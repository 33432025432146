import { createClient, SupabaseClient, RealtimeChannel } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY

if (!supabaseUrl || !supabaseKey) {
    throw new Error('Missing Supabase environment variables')
}

const supabase: SupabaseClient = createClient(supabaseUrl, supabaseKey)

export interface Femboy {
    id: number;
    created_at: string;
    name: string;
    game: string;
}

export const getFemboys = async (): Promise<Femboy[]> => {
    const { data, error } = await supabase
        .from('femboys')
        .select('*')
        .order('created_at', { ascending: false })

    if (error) {
        console.error('Error fetching femboys:', error)
        return []
    }

    return data || []
}

export const setupRealtimeSubscription = (
    callback: (femboy: Femboy, eventType: 'INSERT' | 'UPDATE' | 'DELETE') => void,
    onStateChange: (state: string) => void
) => {
    let channel: RealtimeChannel | null = null;

    const setupChannel = () => {
        if (channel) {
            supabase.removeChannel(channel);
        }

        channel = supabase.channel('public:femboys');

        const handleInserts = (payload: { new: Femboy }) => {
            console.log('INSERT event received:', payload)
            callback(payload.new, 'INSERT')
        }

        const handleUpdates = (payload: { new: Femboy }) => {
            console.log('UPDATE event received:', payload)
            callback(payload.new, 'UPDATE')
        }

        const handleDeletes = (payload: { old: Femboy }) => {
            console.log('DELETE event received:', payload)
            callback(payload.old, 'DELETE')
        }

            ; (channel as any)
                .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'femboys' }, handleInserts)
                .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'femboys' }, handleUpdates)
                .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'femboys' }, handleDeletes)
                .subscribe((status: string) => {
                    console.log('Subscription status:', status)
                    onStateChange(status)
                    if (status === 'SUBSCRIBED') {
                        console.log('Successfully subscribed to real-time changes')
                    }
                })
    }

    setupChannel();

    const intervalId = setInterval(() => {
        if (channel && channel.state !== 'joined') {
            console.log('Channel not joined, attempting to reconnect...')
            setupChannel();
        }
        onStateChange(channel?.state || 'disconnected')
    }, 60000); // Check every minute

    return () => {
        console.log('Cleaning up real-time subscription')
        clearInterval(intervalId)
        if (channel) {
            supabase.removeChannel(channel)
        }
    }
}
