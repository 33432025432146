import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Fuse from 'fuse.js';
import { Femboy, getFemboys, setupRealtimeSubscription } from '../services/api';

const StatusIndicator: React.FC<{ status: string }> = ({ status }) => {
    let statusColor = 'orange';
    let statusText = 'Connecting...';

    if (status === 'SUBSCRIBED') {
        statusColor = 'green';
        statusText = 'Connected';
    } else if (status === 'CLOSED' || status === 'TIMED_OUT') {
        statusColor = 'red';
        statusText = 'Disconnected';
    }

    return (
        <div className="status-indicator-container">
            <div className={`status-indicator ${statusColor}`}>
                <span className="status-tooltip">{statusText}</span>
            </div>
        </div>
    );
};

const FemboyList: React.FC = () => {
    const [femboys, setFemboys] = useState<Femboy[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState<string>('CONNECTING');
    const [hasBeenConnected, setHasBeenConnected] = useState(false);

    const updateConnectionStatus = useCallback((status: string) => {
        console.log('Connection status:', status);
        if (status === 'SUBSCRIBED') {
            setHasBeenConnected(true);
            setConnectionStatus('SUBSCRIBED');
        } else if (hasBeenConnected && (status === 'CLOSED' || status === 'TIMED_OUT')) {
            setConnectionStatus(status);
        }
    }, [hasBeenConnected]);

    useEffect(() => {
        const fetchFemboys = async () => {
            setIsLoading(true);
            const fetchedFemboys = await getFemboys();
            setFemboys(fetchedFemboys);
            setIsLoading(false);
        };

        fetchFemboys();

        const cleanup = setupRealtimeSubscription(
            (femboy, eventType) => {
                setFemboys(prevFemboys => {
                    let newFemboys;
                    switch (eventType) {
                        case 'INSERT':
                            newFemboys = [femboy, ...prevFemboys];
                            break;
                        case 'UPDATE':
                            newFemboys = prevFemboys.map(fb => fb.id === femboy.id ? femboy : fb);
                            break;
                        case 'DELETE':
                            newFemboys = prevFemboys.filter(fb => fb.id !== femboy.id);
                            break;
                        default:
                            newFemboys = prevFemboys;
                    }
                    return newFemboys;
                });
                // If we receive an update, we know we're connected
                if (hasBeenConnected) {
                    setConnectionStatus('SUBSCRIBED');
                }
            },
            updateConnectionStatus
        );

        // Periodic check for connection status
        const intervalId = setInterval(() => {
            if (hasBeenConnected && connectionStatus !== 'SUBSCRIBED') {
                console.log('Checking connection status...');
                // You might want to implement a ping mechanism here
                // For now, we'll just assume it's reconnecting
                setConnectionStatus('CONNECTING');
            }
        }, 30000); // Check every 30 seconds

        return () => {
            cleanup();
            clearInterval(intervalId);
        };
    }, [hasBeenConnected, connectionStatus, updateConnectionStatus]);

    const fuse = useMemo(() => new Fuse(femboys, {
        keys: ['name'],
        threshold: 0.4,
    }), [femboys]);

    const filteredFemboys = useMemo(() => {
        if (!searchTerm) return femboys;
        return fuse.search(searchTerm).map(result => result.item);
    }, [fuse, femboys, searchTerm]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    const searchPlaceholder = `Search ${femboys.length} femboy${femboys.length !== 1 ? 's' : ''}...`;

    return (
        <div className="femboy-list-container">
            <StatusIndicator status={connectionStatus} />
            <h1 className="femboy-list-title">The Femboy List</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            {isLoading ? (
                <div className="femboy-list-loading">
                    <div className="loading-spinner"></div>
                    <p>Loading femboys...</p>
                </div>
            ) : filteredFemboys.length === 0 ? (
                <div className="femboy-list-empty">
                    <p>No femboys found.</p>
                </div>
            ) : (
                <ul className="femboy-list">
                    {filteredFemboys.map((femboy) => (
                        <li key={femboy.id} className="femboy-item">
                            <span className="femboy-name">{femboy.name.toUpperCase()}</span>
                            <div className="femboy-info">
                                {femboy.game && <div className="femboy-game">{femboy.game}</div>}
                                <div className="femboy-date">
                                    {formatDate(femboy.created_at)}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FemboyList;
